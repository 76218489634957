.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}
.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 6px 8px 8px;
    background-color: #000;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 700 15px/1 ‘Lato’, sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: none;
    text-align: center;
}
.ribbon-mobile span {
    font: 700 12px/1 ‘Lato’, sans-serif;
}

/* top left*/
.ribbon-top-left {
    top: 0px;
    left: 0px;
    z-index: 9;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}
.ribbon-top-left span {
    right: -24px;
    top: 28px;
    transform: rotate(-45deg);
}
.ribbon-top-left-mobile span {
    right: -18px;
    top: 24px;
}

.ribbon-mobile-landscape {
    z-index: 8;
    top: 120px;
}

.ribbon-mobile {
    z-index: 8;
    top: 0px;
}
