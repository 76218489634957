.label {
    padding: 8px;
}

.label:after {
    content: ' *';
    color: red;
}

.grecaptcha-badge {
    visibility: hidden !important;
    opacity: 0;
}
