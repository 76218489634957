.MuiAccordionSummary-content {
    margin: 12px 0px 12px 36px !important;
    display: flex;
    justify-content: center !important;
    flex-grow: 1 !important;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiOutlinedInput-input {
    padding: 10px 14px;
}
